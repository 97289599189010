<template>
    <div class="cardLostOrStop">
       <div class="scroll-box">
            <div class="tr-li card-tr">
                <div class="lable-txt">卡号：</div>
                <div class="from-input">
                    <div class="card-no input-box"><input-pattern keyBoard pattern="code" placeholder="请输入卡号或读卡" v-model="cardNo" @keyup.enter="selectReadCard()" :maxlength="20"/><i class="iconfont icon-sousuo" @click="selectReadCard()"></i></div> 
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
            </div>
            <div class="info-box">
                <div class="title">客户资料信息</div>
                <div class="tr-li">
                    <div class="lable-txt">会员等级：</div>
                    <div class="from-input"><div class="input-box"><input type="text" :value="memberInfo?.Bestech_Card_KindName" readonly/></div></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">客户姓名：</div>
                    <div class="from-input"><div class="input-box"><input type="text" :value="memberInfo?.MemberName" readonly/></div></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">手机号：</div>
                    <div class="from-input"><div class="input-box"><input type="text" :value="memberInfo?.Mobile" readonly/></div></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">生日：</div>
                    <div class="from-input"><div class="input-box"><input type="text" :value="memberInfo?.Birthday" readonly/></div></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">性别：</div>
                    <div class="from-input"><div class="input-box"><input type="text" :value="{0:'男',1:'女'}[memberInfo?.Gender]" readonly/></div></div>
                </div>  
                <div class="tr-li">
                    <div class="lable-txt">卡状态：</div>
                    <div class="from-input"><div class="input-box"><input type="text" :value="memberInfo?.Card_Status" readonly/></div></div>
                </div>
                <div class="title">卡资产信息</div>
                <div class="tr-li">
                    <div class="lable-txt">余额：</div>
                    <div class="from-input">
                        <div class="input-box"><input class="text-right" type="text" :value="memberInfo?.Card_Money" readonly/>
                        <p class="describe" :style="memberInfo?'':'visibility: hidden'">其中赠送金额{{memberInfo?.Gift_Money}}元</p></div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">积分：</div>
                    <div class="from-input">
                        <div class="input-box"><input class="text-right" type="text" :value="memberInfo?.Card_PilePoint" readonly/>
                        <p class="describe"></p></div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">券：</div>
                    <div class="from-input ticket" @click="ticketClick()">
                        <div class="input-box"><input class="text-right" :value="(memberInfo?.Coupons?.length||'0')+'张'" type="text"  readonly/><i class="iconfont">></i>
                        <p class="describe"></p></div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="footer-box">
            <button class="btn" @click="back()">返回</button>
            <button class="btn selected" v-if="data.type==2" v-show="!memberInfo || memberInfo?.Card_StatusEnum==1 || memberInfo?.Card_StatusEnum==3" @click="cardOperation()">{{({1:'停用',3:'恢复停用'}[memberInfo?.Card_StatusEnum])||"停用或恢复停用"}}</button>
            <button class="btn selected" v-else-if="!memberInfo || memberInfo?.Card_StatusEnum==1 || memberInfo?.Card_StatusEnum==2" @click="cardOperation()">{{({1:'挂失',2:'恢复挂失'}[memberInfo?.Card_StatusEnum])||"挂失或恢复挂失"}} </button>
        </div>
        <!-- 券列表 -->
        <modal-load :isShow="ticketShow">
            <ticket-model :isShow="ticketShow" @closeModel="ticketShow=false" :Coupons="memberInfo?.Coupons"></ticket-model>
        </modal-load>
        <!-- 选择卡信息 -->
        <modal-load :isShow="cardMemberShow">
            <select-card-member-info :isShow="cardMemberShow" @closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo"></select-card-member-info>
        </modal-load> 
    </div>
</template>

<script>
import { ticketModel,selectCardMemberInfo } from '../model'

export default {
    name:'cardLostOrStop',
    components:{ticketModel,selectCardMemberInfo},
    props:{
        data:{
            default:{
                /**操作类型 1 挂失或恢复挂失 2停用卡*/
                type:1
            }
        }
    },
    data(){
        return {
            /**卡号 */
            cardNo:'',
            /**卡id */
            card_AutoID:'',
            /**会员信息 */
            memberInfo:undefined,
            /**券列表 */
            ticketShow:false,
             /**选择卡信息 */
            cardMemberShow:false,
            /**多个卡信息 */
            cardMemberList:[],
        }
    },
    watch:{
        cardNo(){
            this.memberInfo=null;
        }
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /*刷新当前页面 */
        reload() {
            this.$emit("reload");
        },
        ticketClick(){
            if(this.memberInfo){
                this.ticketShow = true;
            }
        },
        /**点击查询图标读卡   m1卡 Card_AutoID 卡id,Card_SN 内卡号 */
        selectReadCard(Card_AutoID,Card_SN){
            if(!this.cardNo && !Card_SN){
                this.$message.warning('请填写会员卡号')
                return
            }
            const loading = this.$loading({
                text: "读取会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",{
                User_ID:this.userInfo?.User_ID,
                DynamicNo:this.cardNo,
                Business_Sense:3,//业务场景 3：挂失，取消挂失，补卡，退卡
                Card_AutoID:Card_AutoID,
                Card_SN:Card_SN,
                IsGetNotIssuedCard:true
            }).then((data)=>{
                loading.close();
                console.log('会员信息:',data)
                if(data.ResponseHeader.ResultCode==0){
                    if(data.ResponseBody.length>1){
                        this.cardMemberList=data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.selectCardInfo(data.ResponseBody[0])
                    }
                }else{
                    this.memberInfo=null,
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.memberInfo=null,
                this.$message.error('会员信息读取失败：'+e);
                console.log('会员信息读取失败：'+e);
            })
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.cardNo=d.data.cardNo;
                        this.selectReadCard(d.data.cardID,d.data.snr);
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**多个卡信息时选择一个 */
        selectCardInfo(data){
            this.cardMemberShow=false;
            this.cardNo=data.Card_No;
            this.$nextTick(()=>{
                this.memberInfo = data;
                this.card_AutoID = this.memberInfo?.Bestech_Card_AutoID;
            })
        },
        /**卡操作 */
        cardOperation(){
            if(!this.cardNo){
                 this.$message.warning('请先输入卡号');
                 return
            }
            if(!this.memberInfo){
                 this.$message.warning('请先获取会员卡数据');
                 return
            }
            
            let Event_Enum=1,Event_Type="";
            if(this.data.type==2){//停用
                if(this.memberInfo.Card_StatusEnum==1){//停用
                    Event_Enum=4;
                    Event_Type="停用";
                }else if(this.memberInfo.Card_StatusEnum==3){//恢复停用
                    Event_Enum=5;
                    Event_Type="恢复停用";
                }else{
                    return;
                }
            }else{
                if(this.memberInfo.Card_StatusEnum==1){//挂失
                    Event_Enum=1;
                    Event_Type="挂失";
                }else if(this.memberInfo.Card_StatusEnum==2){//取消挂失，
                    Event_Enum=2;
                    Event_Type="恢复挂失";
                }else{
                    return;
                }
            }


            const loading = this.$loading({
                text: Event_Type+"操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.CardOperation",{
                User_ID:this.userInfo?.User_ID,
                User_Key:'',
                Event_Enum:Event_Enum,// 操作类型值  1：挂失，  2：取消挂失，  3：退卡，  4 :冻结卡（停用）， 5：解冻卡（撤销停用）
                CardOperationEvent:{
                    Card_AutoID:this.card_AutoID,
                    Card_No:this.cardNo,
                    Pos_Czy:userInfo.Login_Name,//操作员
                    Pos_Name:userInfo?.Department_Name, //开卡站点
                }
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(Event_Type+"操作失败："+d.ResponseHeader.ResultDesc);
                    return;
                }
                this.$message.success(Event_Type+"操作完成");
                this.reload();
            }).catch((e)=>{
                loading.close();
                this.$message.error(Event_Type+'操作失败：'+e);
                console.log(Event_Type+'操作失败：'+e);
            })
        }
    }
}
</script>

<style lang="scss">
@import "./cardLostOrStop.scss";
</style>